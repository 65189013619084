import * as p1 from "./p_1";
import * as p2 from "./p_2";
import * as p3 from "./p_3";
import * as p4 from "./p_4";
import * as p5 from "./p_5";
import * as p6 from "./p_6";
import * as p7 from "./p_7";
import * as p8 from "./p_8";
import * as p9 from "./p_9";
import * as p10 from "./p_10";
import * as p11 from "./p_11";
import * as p12 from "./p_12";
import * as p13 from "./p_13";

export const problems = [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13
];
